.home-hero {
	display: flex;
  justify-content: center;
  align-items: center;
	width: 100%;
	height: 640px;
	background: url(/images/home-hero-new.jpeg), linear-gradient(rgba(0,0,0,0.25),rgba(0,0,0,0.25));
	background-size: cover;
  background-blend-mode: overlay;
	background-position: center;
	h1 {
    color: $white;
    text-align: center;
    font-size: 3.5rem;
		text-shadow: 3px 1px 3px rgba(0, 0, 0, 0.57);
  }
}
