.searchHeader {
	display: block;
	font-size: .9em;
	text-transform: uppercase;
	padding: 8px 10px;
	border-bottom: 1px solid $border-color;
}

.cat-list {
	display: block;
	padding: 0 10px;
	a {
		text-decoration: none;
		color: $black;
		.cat-name {
		    font-size: .9em;
		    font-weight: 700;
		}
		.cat-bread {
			font-size: .9em;
			color: $dark-grey;
		}
	}
}

.prod-search-list {
	display: block;
	padding: 0 10px;
	li {
		padding: 5px 0;
		border-bottom: 1px solid $border-color;
	}
	a {
		text-decoration: none;
		.prod-title {
			color: $black;
			font-weight: 700;;
		}
		.prod-sku {
			color: $dark-grey;
			font-size: .9em;
		}
	}
}

.search-view-all {
	display: block;
	padding: 10px;
	color: $black;
}
