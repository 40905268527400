span.ingress {
  display: block;
  font-size: 1.25rem;
  color: $black-lighter;
  max-width: 1080px !important;
  margin: auto;
}

.image-right {
  text-align: right;
}

.row.narrow {
  display: block !important;
	width: 100% !important;
  max-width: 1080px !important;
  margin: 0 auto;
}

img {
	display: block;
	max-width: 100% !important;
}

/*--
|* contact page
--*/

input.form-control, textarea.form-control {
	display: block;
	border: none;
	padding: 10px;
	background: $light-grey;
	border-radius: 0;
}

.btn-send.btn-outline-success {
	float: right;
	border: 1px solid $accent-color-red;
	color: $accent-color-red;
	border-radius: 0;
	&:hover {
		border: 1px solid $accent-color-red;
		background: $accent-color-red;
		color: $white;
	}
}

.contact-phone {
	display: block;
	color: $black;
	text-decoration: none;
	margin: 0;
	&:before {
		content: "";
		display: inline-block;
		position: relative;
		top: 6px;
		width: 24px;
		height: 24px;
		background: url(/images/phone.svg) no-repeat;
		background-size: contain;
		margin: 0 5px 0 0;
	}
	&:hover {
		display: block;
		color: $black;
		text-decoration: underline;
		margin: 0;
	}
}

.contact-email {
  display: block;
  color: $black;
  text-decoration: none;
  margin: 0;
  &:before {
    content: "";
    display: inline-block;
    position: relative;
    top: 6px;
    width: 24px;
    height: 24px;
    background: url(/images/mail.svg) no-repeat;
    background-size: contain;
    margin: 0 5px 0 0;
    }
	&:hover {
		display: block;
	  color: $black;
	  text-decoration: underline;
	  margin: 0;
	}
}

// tabs

.tab-content {
    padding-bottom: 1.3rem
}

.form-control {
    background-color: rgb(241, 243, 247);
    border: none
}

.form-control span {
    margin-left: 0.5rem;
    padding: 1px 10px;
    color: white;
    background-color: rgb(143, 143, 143);
    border-radius: 4px;
    font-weight: 600
}

.third {
    padding: 0 1.5rem 0 1.5rem
}

label {
    font-weight: 500;
    color: rgb(104, 104, 104)
}

.btn-success {
    float: right
}

.form-control:focus {
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.075) inset, 0px 0px 7px rgba(0, 0, 0, 0.2)
}

select {
    -webkit-appearance: none;
    -moz-appearance: none;
    text-indent: 1px;
    text-overflow: ""
}

ul {
    list-style: none;
    margin-top: 1rem;
    padding-inline-start: 0
}

.search {
    padding: 0 1rem 0 1rem
}

.ccontent li .wrapp {
    padding: 0.3rem 1rem 0.001rem 1rem
}

.ccontent li .wrapp div {
    font-weight: 600
}

.ccontent li .wrapp p {
    font-weight: 360
}

.ccontent li:hover {
    background-color: rgb(117, 93, 255);
    color: white
}

.addinfo {
    padding: 0 1rem
}

.sponsors {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
}

.my-account {
  a {
    color: $black;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
  .btn {
    &:hover {
      color: $white;
      text-decoration: none;
    }
  }
  .order-summary {
    display: block;
    margin: 0 0 20px 0;
    span.yellow-bg {
      background: $accent-color-yellow;
    }
  }
}
