.products {
	width: 220px;
  li:nth-child(1) {
    a {
      border-bottom: 1px solid $accent-color-red;
    }
  }
  li:nth-child(2) {
    a {
      border-bottom: 1px solid $accent-color-blue;
    }
  }
  li:nth-child(3) {
    a {
      border-bottom: 1px solid $accent-color-yellow;
    }
  }
  li:nth-child(4) {
    a {
      border-bottom: 1px solid $accent-color-green;
    }
  }
  li:nth-child(5) {
    a {
      border-bottom: 1px solid $accent-color-grey;
    }
  }
  li:nth-child(6) {
    a {
      border-bottom: 1px solid $white;
    }
  }
  .megasubmenu {
    li {
      a {
        border-bottom: 1px solid $dark-grey;
      }
    }
  }
}

.megasubmenu{ padding:1rem; }

/* ============ desktop view ============ */
@media all and (min-width: 992px) {
	.dropdown-menu .dropdown-toggle::after{
		border-top: .3em solid transparent;
	    border-right: 0;
	    border-bottom: .3em solid transparent;
	    border-left: .3em solid;
	}

	.megasubmenu {
		left:100%; top:0; min-height: 100%; min-width:650px;
    li {
      float: left;
      width: calc(33% - 30px);
      margin: 0 15px;
    }
	}

	.dropdown-menu > li:hover .megasubmenu{
		display: block;
	}
}
/* ============ desktop view .end// ============ */

@media (max-width: 991px) {

	.products {
		width: 100% !important;
	}

}
