.product-list,
.product-tabs,
.related-products {
  background: $white;
	h6 {
		a {
			color: $black;
			text-decoration: none;
			font-size: 1.1rem;
			line-height: 1.4em;
		}
	}
	.img-fluid {
		height: 240px;
		object-fit: contain;
		object-position: center;
		margin: auto;
	}
}

.product-list {
  padding-bottom: 80px;
}

.prodcat {
  a {
    display: block;
    text-align: center;
    font-size: 1rem;
    color: $black;
    text-decoration: none;
    padding: 15px 0 15px 0;
    margin: 0 0 10px 0;
    border-bottom: 1px solid $border-color;
  }
}

.card:empty {
  display: none;
}

.tab-regular .nav.nav-tabs {
    border-bottom: transparent
}

.tab-regular .nav.nav-tabs .nav-link {
    display: block;
    padding: 17px 49px;
    color: $black;
    background-color: $light-grey;
    margin-right: 5px;
    border-color: $light-grey;
}

.tab-regular .nav-tabs .nav-link.active {
    background-color: $white;
    border-color: #e6e6f2 #e6e6f2 #fff;
    color: #5969ff
}

.tab-regular .tab-content {
    background-color: #fff;
    padding: 30px;
    border: 1px solid #e6e6f2;
    border-radius: 4px;
    border-top-left-radius: 0px
}

.breadcrumb-item {
	a {
		color: $black;
		text-decoration: none;
		&:hover {
			color: $black;
			text-decoration: underline;
		}
	}
}

.flex-row {
  display: flex;
  background: #fff;
  border-bottom: 1px solid #ddd;
  padding: 5px;
}

.dataLabel {
  display: inline-block;
  width: 30%;
  font-weight: 700;
  padding: 10px 0;
}

.dataValue {
  display: inline-block;
  width: 68%;
  padding: 10px 0px;
}

a[href$=".pdf"],
a[href$=".PDF"] {
	display: block;
	height: 40px;
	background: url(/images/icons/pdf.svg) no-repeat;
	background-size: 32px 32px;
	background-position: 0 50%;
	padding: 9px 0 0 38px;
	margin: 5px 0;
	color: $black;
}

.tab-content a[href$=".jpg"],
.tab-content a[href$=".JPG"] {
  display: block;
	height: 40px;
  background: url(/images/icons/jpg.svg) no-repeat;
  background-size: 32px 32px;
  background-position: 0 50%;
  padding: 9px 0 0 38px;
  margin: 5px 0;
  color: $black;
}

.tab-content a[href$=".gif"],
.tab-content a[href$=".GIF"] {
    display: block;
    height: 40px;
    background: url(/images/icons/gif.svg) no-repeat;
    background-size: 32px 32px;
    background-position: 0 50%;
    padding: 9px 0 0 38px;
    margin: 5px 0;
    color: $black;
}

.tab-content a[href$=".png"],
.tab-content a[href$=".PNG"] {
    display: block;
    height: 40px;
    background: url(/images/icons/png.svg) no-repeat;
    background-size: 32px 32px;
    background-position: 0 50%;
    padding: 9px 0 0 38px;
    margin: 5px 0;
    color: $black;
}

a[href$=".zip"],
a[href$=".ZIP"] {
    display: block;
    height: 40px;
    background: url(/images/icons/zip.svg) no-repeat;
    background-size: 32px 32px;
    background-position: 0 50%;
    padding: 9px 0 0 38px;
    margin: 5px 0;
    color: $black;
		&:before {
			content: "";
		}
}

a[href$=".doc"],
a[href$=".DOC"],
a[href$=".docx"],
a[href$=".DOCX"] {
    display: block;
    height: 40px;
    background: url(/images/icons/doc.svg) no-repeat;
    background-size: 32px 32px;
    background-position: 0 50%;
    padding: 9px 0 0 38px;
    margin: 5px 0;
    color: $black;

    &:before {
        content: "";
    }
}

.product-details a[href$=".pdf"],
.product-details a[href$=".PDF"] {
  display: block;
	width: 98px;
	height: auto !important;
  background: url(/images/icons/pdf.svg) no-repeat $white;
  background-size: 26px 26px !important;
  background-position: 10px 50% !important;
  padding: 10px 10px 10px 50px !important;
  margin: 5px 0 30px 0;
  color: $black;
	border: 1px solid $border-color;
	text-decoration: none;
}

.variation-list {
	li {
		display: inline-block;
		background: $white;
		border: 1px solid $border-color;
		margin-bottom: 10px;
		a {
			display: inline-block;
			padding: 10px 20px;
			color: $black;
			text-decoration: none;
			&:hover {
				color: $black;
				text-decoration: none;
			}
		}
		&:hover {
			background: $border-color;
		}
	}
	li.active {
		display: inline-block;
		padding: 10px 20px;
		background: #E8F4FC !important;
		border: 1px solid rgb(46, 147, 223) !important;
	}
	li.disabled {
		display: inline-block;
		padding: 10px 20px;
		background: #f5f5f5 !important;
		border: 1px solid #ddd !important;
		color: #999 !important;
	}
}

.carousel-indicators {
  position: static;
}
.carousel-indicators li {
  width: 300px;
  height: 100%;
  opacity: 0.8;
}

.carousel-indicators [data-bs-target] {
    box-sizing: content-box;
    flex: 0 1 auto;
    width: 140px;
    height: 80px;
    padding: 0;
    margin-right: 3px;
    margin-left: 3px;
    text-indent: -999px;
    cursor: pointer;
    background-color: #fff;
    background-clip: padding-box;
    border: 0;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    opacity: 1;
    transition: opacity 0.6s ease;
    overflow: hidden;
		display: inline-block;
}

.product-list .carousel-item {
	height: 624px;
	max-height: 624px;
	background: $white;
	border: 1px solid $border-color;
	img	{
		width: 100%;
		height: 624px;
		object-fit: contain;
	}
}

.product-image {
	img {
	    width: 100%;
	    height: 624px;
			background: $white;
			border: 1px solid $border-color;
	    object-fit: contain;
	}
	.carousel-control-prev {
	    left: 0 !important;
	}
	.carousel-control-next {
	    right: 0 !important;
	}
}

.carousel-indicators {
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
  display: block;
  justify-content: start;
  padding: 0;
  margin-right: 0%;
  margin-bottom: 1rem;
  margin-left: 0%;
  list-style: none;
  flex-direction: row;
  flex-wrap: nowrap;
  overflow-x: auto;
  height: 125px;
  white-space: nowrap;
	a {
		img {
			width: 80px;
			height: 80px;
			object-fit: contain;
		}
	}
}

.main-video {
	position: relative;
	margin-top: 150px;

	.video {
		position: relative;
    width: 100%;
    height: 0;
    padding-bottom: 56.25%;

    iframe {
	    position: absolute;
	    top: 0;
	    left: 0;
	    width: 100%;
	    height: 100%;
		}
	}
}

p.price {
	font-size: 26px;
}

p.in-stock {
	color: $accent-color-green;
}

p.no-stock {
    color: $accent-color-red;
}

p.purchase-order-header {
	font-weight: 700;
	color: $black;
}

ul.purchase-order-list {
	padding: 0;
	margin: 0 0 30px 0;
	li {
		color: $black;
	}
}

.pagination {
  .page-item.disabled {
    a {
      background: $light-grey;
      color: $dark-grey;
    }
  }
  .page-item {
    a {
      color: $black;
      &:hover {
        background: $dark-grey;
        color: $white;
      }
    }
  }
}

.spare-search {
	display: block;
	width: 100%;
	padding: 10px;
	border: 1px solid $border-color;
}

#spares {
	li {
	  display: block;
	  padding: 0 20px 15px 20px;
	  border-bottom: 1px solid $border-color;
		a {
			color: $black;
			font-size: 1.2em;
			text-decoration: none;
			&:hover {
				text-decoration: underline;
			}
		}
	}
}

.product-list {
	h6 {
		display: block;
		display: -webkit-box;
		max-width: 100%;
		height: 48.2px;
		margin: 0 auto;
		font-size: 18px;
		line-height: 1.2;
		-webkit-line-clamp: 2;
		-webkit-box-orient: vertical;
		overflow: hidden;
		text-overflow: ellipsis;
	}
	p.product-list-price {
		font-size: 18px;
		font-weight: 400;
		margin: 0 0 10px 0;
	}
}

.quote {
	display: block;
	margin: 15px 0 0 0;
	p {
		display: inline-block;
		padding: 10px 20px 10px 42px;
		background: url(/images/icons/quote.svg) no-repeat $light-blue;
		background-size: 20px 20px !important;
		background-position: 10px 50% !important;
		border: 1px solid $border-color;
		&:hover {
			background: url(/images/icons/quote.svg) no-repeat #c0e7ff !important;
			background-size: 20px 20px !important;
			background-position: 10px 50% !important;
			cursor: pointer;
		}
	}
	a {
	    display: inline-block;
	    padding: 10px 20px 10px 42px;
	    background: url(/images/icons/quote.svg) no-repeat $light-blue;
	    background-size: 20px 20px !important;
	    background-position: 10px 50% !important;
	    border: 1px solid $border-color;
			color: $black;
			text-decoration: none;
			margin-bottom: 10px;
	    &:hover {
	      background: url(/images/icons/quote.svg) no-repeat rgba(0, 0, 0, .05) !important;
				background-size: 20px 20px !important;
				background-position: 10px 50% !important;
	    }
	}
}

p.remove {
	color: $accent-color-red;
	font-size: 20px;
	cursor: pointer;
}

.product-details .card {
  border: none !important;
}

body .product-details .card.internal {
		padding: 1rem !important;

    border: 1px solid #ddd !important;
		select {
			padding: 10px;
			border: 1px solid #ddd;
			background: url(/images/icons/down-arrow.svg) no-repeat #fff;
			background-size: 16px 16px;
			background-position: 95% 50%;
		}
		.customer-price {
			font-size: 1.3rem;
		}
		textarea {
			font-size: 1.2rem;
		}
}

#document {
  p.title {
    text-transform: uppercase;
  }
}

/*
**
** New slider
**
*/

.uk-slideshow-items {
	width: 100%;
	height: 624px !important;
	li {
		width: 100%;
		height: 624px !important;
		a {
			img {
				width: 100%;
				height: 624px !important;
				object-fit: contain;
				object-position: center;
			}
		}
	}
}

.product-image .uk-thumbnav img {
    width: 100px !important;
    height: 100px !important;
    background: #ffffff !important;
    border: 1px solid #ddd !important;
    -o-object-fit: contain;
    object-fit: contain;
}

.uk-thumbnav {
	flex-wrap: nowrap !important;
	overflow-x: auto;
	li {
		a {
			width: 100px !important;
			height: 100px !important;
		}
	}
}

.product-tabs {
	position: relative;
	margin-top: 80px;
}

.uk-lightbox {
  z-index: 999999 !important;
  background: rgba(0,0,0,0.9) !important;
}

.uk-card-primary.uk-card-body .uk-slidenav, .uk-card-primary>:not([class*=uk-card-media]) .uk-slidenav, .uk-card-secondary.uk-card-body .uk-slidenav, .uk-card-secondary>:not([class*=uk-card-media]) .uk-slidenav, .uk-light .uk-slidenav, .uk-offcanvas-bar .uk-slidenav, .uk-overlay-primary .uk-slidenav, .uk-section-primary:not(.uk-preserve-color) .uk-slidenav, .uk-section-secondary:not(.uk-preserve-color) .uk-slidenav, .uk-tile-primary:not(.uk-preserve-color) .uk-slidenav, .uk-tile-secondary:not(.uk-preserve-color) .uk-slidenav {
    color: rgba(0,0,0,.7) !important;
}

.incoming-list {
	list-style-type: none;
	padding: 0;
	margin: 0;
	color: $black;
	li {
		color: $black;
	}
}

p.unavailable {
	color: $accent-color-red;
}

p.in-stock {
	display: inline-block;
	background: url(/images/icons/check.svg) no-repeat;
	background-size: 20px 20px;
	background-position: center left;
	padding: 0 0 0 25px;
	margin: 0 0 10px 0;
}

p.out-of-stock {
  display: inline-block;
  background: url(/images/icons/out-of-stock.svg) no-repeat;
  background-size: 15px 15px;
  background-position: center left;
  padding: 0 0 0 20px;
  margin: 0 0 10px 0;
}

p.order-product {
  display: inline-block;
  background: url(/images/icons/order-product.svg) no-repeat;
  background-size: 15px 15px;
  background-position: center left;
  padding: 0 0 0 20px;
  margin: 0 0 10px 0;
}

.calculator input {
	width: 100%;
	padding: 8px;
	border: 1px solid #ddd;
}

.calculator button {
	width: 100%;
	padding: 8px;
	border: 1px solid #ddd;
}

.internal textarea {
	width: 100%;
	padding: 8px;
	border: 1px solid #ddd;
}

.badge.news {
	top: 10px;
	left: 20px;
}
