#staffContainer {
  .btn {
    background: $light-grey;
    &:hover {
      background: $dark-grey;
      color: $white;
    }
  }
  .btn.active {
    background: $accent-color-blue;
    color: $white;
    &:hover {
      background: $black;
    }
  }
}

.staff-item {
  .staff-image {
    display: block;
    margin: 0 0 10px 0;
  }
  .staff-header {
    .name {
      font-weight: 700;
    }
  }
  .staff-contact {
    .staff-mail {
      color: $black;
      text-decoration: none;
      line-height: 2em;
      &:before {
        content: "";
        display: inline-block;
        position: relative;
        top: 3px;
        margin: 0 8px 0 0;
        width: 16px;
        height: 16px;
        background: url(/images/staff-mail.svg) no-repeat;
        background-size: contain;
      }
    }
    .staff-phone {
      color: $black;
      text-decoration: none;
      line-height: 2em;
      &:before {
        content: "";
        display: inline-block;
        position: relative;
        top: 1px;
        margin: 0 8px 0 0;
        width: 14px;
        height: 14px;
        background: url(/images/staff-phone.svg) no-repeat;
        background-size: contain;
      }
    }
    .staff-cell {
      color: $black;
      text-decoration: none;
      line-height: 2em;
      &:before {
        content: "";
        display: inline-block;
        position: relative;
        top: 2px;
        margin: 0 8px 0 0;
        width: 16px;
        height: 16px;
        background: url(/images/staff-cell.svg) no-repeat;
        background-size: contain;
      }
    }
  }
}

#sverige, #estland, #frankrike {
  padding-top: 176px;
  margin-top: -176px;
}

#polen {
  padding-top: 0;
  margin-top: 0;
}

@media (max-width: 1680px) {
  #polen {
    padding-top: 176px;
    margin-top: -176px;
  }
}

@media (max-width: 1536px) {
  #polen {
    padding-top: 276px;
    margin-top: -276px;
  }
}
