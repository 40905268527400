.header-bg {
	background: $black;
	display: block;
	width: 100%;
  z-index: 9999;
}

/* The sticky class is added to the header with JS when it reaches its scroll position */
.sticky {
    position: fixed;
    top: 0;
    width: 100%
}

/* Add some top padding to the page content to prevent sudden quick movement (as the header gets a new position at the top of the page (position:fixed and top:0) */
.sticky+.content {
    padding-top: 102px;
}

.logo {
  max-height: 50px;
  margin-bottom: 15px;
	transition: all 0.4s ease;
}

.sticky .logo {
	max-height: 25px;
	margin-bottom: 5px;
	transition: all 0.4s ease;
}

.sticky a.logo-link-swedmach img {
    position: relative;
    top: 5px;
}

.topbar {
	display: block;
	text-align: right;
}

ul.topbar-menu {
	list-style: none;
	margin: 0;
	padding: 0;
	li {
		display: inline-block;
		margin: 0;
		padding: 0;
		a {
			display: inline-block;
			color: $white;
			text-decoration: none;
			padding: 10px 10px;
			text-transform: uppercase;
			font-size: .9rem;
			font-weight: 300;
		}
		a.lang {
			padding: 10px 0 10px 1px;
			position: relative;
			top: 13px;
		}
	}
}

.navbar {
	display: block;
	position: relative;
	left: -1.4rem;
	margin-bottom: 5px;
}

.navbar-nav {
	li {
		a.nav-link {
			color: $white;
			font-weight: 300;
			padding: 0.5rem 3.5rem 0.5rem 1rem !important;
			border-right: 1px solid #303030;
			background: $black;
			&:hover {
				background: $black-lighter;
			}
		}
	}
}

.dropdown-toggle::after {
    display: inline-block;
    position: absolute;
    top: 18px;
    right: 12px;
    /* background-position: center right; */
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent;
}

.dropdown-menu {
	background: $black-lighter;
	border-radius: 0;
	padding: 0;
	li {
		a {
			display: block;
			background: $black-lighter;
			color: $white;
			padding: 15px;
			border-bottom: 1px solid #303030;
			&:hover {
				background: $black;
			}
		}
	}
	li:last-child {
		a {
			border-bottom: none;
		}
	}
}

.product-search {
	display: block;
	width: 98%;
	padding: 8px;
	font-size: .9rem;
	margin-top: 20px;
}

::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: #8f8f8f;
		font-weight: 300;
}

::-moz-placeholder {
    /* Firefox 19+ */
    color: #8f8f8f;
		font-weight: 300;
}

:-ms-input-placeholder {
    /* IE 10+ */
    color: #8f8f8f;
		font-weight: 300;
}

:-moz-placeholder {
    /* Firefox 18- */
    color: #8f8f8f;
		font-weight: 300;
}

a.logo-link-intra {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

a.logo-link-swedmach {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

a.logo-link-swedmach img {
	max-height: 40px;
	position: relative;
	top: 10px;
}

.navbar-toggler {
	border: 1px solid $dark-grey;
}

.navbar-toggler-icon {
	background: url(/images/icons/menu.svg) no-repeat;
	background-size: 32px 32px;
	background-position: center left;
}

.header-search-mobile {
	display: none;
}

.header-search-desktop {
	display: block;
	position: relative;
	top: -10px;
	right: -12px;
	.close-button[data-v-40f8a9fe] {
		top: 30px;
	}
	.search-result-box[data-v-40f8a9fe] {
	    position: absolute;
	    top: 61px;
	    left: 0px;
	    right: 0px;
	    padding: 5px;
	    background-color: #fff;
	    border: 1px solid #ccc;
	    z-index: 10000;
			max-height: 80vh;
			overflow: scroll;
	}
}
