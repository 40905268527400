
/*
|--------------------------------------------------------------------------
| Bootstrap
|--------------------------------------------------------------------------
*/

@import "~bootstrap/scss/bootstrap";

/*
|--------------------------------------------------------------------------
| Fonts
|--------------------------------------------------------------------------
*/

@import "_fonts";

/*
|--------------------------------------------------------------------------
| Variables
|--------------------------------------------------------------------------
*/

@import "variables";

/*
|--------------------------------------------------------------------------
| Custom Multilevel Dropdown
|--------------------------------------------------------------------------
*/

@import "dropdown";

/*
|--------------------------------------------------------------------------
| Header
|--------------------------------------------------------------------------
*/

@import "_header";
@import "_product-nav";

/*
|--------------------------------------------------------------------------
| News
|--------------------------------------------------------------------------
*/

/*
|--------------------------------------------------------------------------
| Content styles
|--------------------------------------------------------------------------
*/

@import "_hero";
@import "_content";
@import "_products";
@import "_staff";
@import "_news";
@import "_slider";
@import "_search";

/*
|--------------------------------------------------------------------------
| Footer
|--------------------------------------------------------------------------
*/

@import "_footer";

/*
|--------------------------------------------------------------------------
| Mobilt
|--------------------------------------------------------------------------
*/

@import "_mobile";

html {
  overflow-x: hidden;
}

body {
	font-family: $font-family;
	font-size: $default-font-size;
}

.pt-80 {
  padding-top: 80px;
}

.mt-80 {
    margin-top: 80px;
}

.pb-80 {
  padding-bottom: 80px;
}

.mb-80 {
	margin-bottom: 80px;
}

.lighter-grey, .light-grey {
  background-color: $light-grey;
}

.border-none {
  border: none !important;
}

.header-bg {
	position: sticky;
}

body button,
body [type=button],
body [type=reset],
body [type=submit] {
    -webkit-appearance: none !important;
}
