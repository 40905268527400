.carousel-item {
	max-height: 430px;
  img {
    width: 100%;
    height: 430px;
    object-fit: contain;
    object-position: top center;
  }
}

#productPhotos {
  .carousel-item {
    max-height: 640px;
    img {
      width: 100%;
      height: 640px;
      object-fit: contain;
      object-position: center;
    }
  }
}

.row.equal {
    display: flex;
    flex-wrap: wrap;
}

body span.sr-only {
	display: none !important;
}

.carousel-control-prev span.sr-only,
.carousel-control-next span.sr-only {
	color: $black;
}

.carousel-control-next {
	right: -100px !important;
}

.carousel-control-prev {
    left: -100px !important;
}

.carousel-control-prev,
.carousel-control-next {
    position: absolute;
    top: 0;
    bottom: 0;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 5% !important;
    padding: 0;
    color: #fff;
    text-align: center;
    background: none;
    border: 0;
    opacity: 0.5;
    transition: opacity 0.15s ease;
}
