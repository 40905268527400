$black: #000000 !important;
$black-lighter: #101820 !important;
$white: #ffffff !important;
$light-grey: #f1eff0 !important;
$lighter-grey: #f9f9f9 !important;
$dark-grey: #888 !important;
$light-blue: #E8F4FC !important;
$font-family: "Roboto", Arial, Helvetica, sans-serif !important;
$default-font-size: 16px !important;
$accent-color-red: #c30011 !important;
$accent-color-blue: #009de0 !important;
$accent-color-yellow: #feed01 !important;
$accent-color-green: #249545 !important;
$accent-color-grey: #393939 !important;
$border-color: #ddd !important;
