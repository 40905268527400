
/*
|--------------------------------------------------------------------------
| Desktop
|--------------------------------------------------------------------------
*/

@media all and (min-width: 992px) {
  .dropdown-menu li.has-megasubmenu { position: static!important;}
}



/*
|--------------------------------------------------------------------------
| Small Devices
|--------------------------------------------------------------------------
*/

@media (max-width: 991px) {
  .dropdown-menu .dropdown-menu {
    margin-left:0.7rem;
    margin-right:0.7rem;
    margin-bottom: .5rem;
  }
}

.megasubmenu {
    padding: 1rem;
}

/* ============ desktop view ============ */
@media all and (min-width: 992px) {
    .dropdown-menu .dropdown-toggle::after {
        border-top: .3em solid transparent;
        border-right: 0;
        border-bottom: .3em solid transparent;
        border-left: .3em solid;
    }

    .megasubmenu {
        left: 100%;
        top: 0;
        min-height: 100%;
        min-width: 500px;
    }

    .dropdown-menu>li:hover .megasubmenu {
        display: block;
    }
}

/* ============ desktop view .end// ============ */

.dropdown-menu>li.has-megasubmenu a {
	background-image: url(/images/icons/menu-arrow.svg) !important;
	background-repeat: no-repeat !important;
	background-size: 14px 14px !important;
	background-position: 97% 50% !important;
	&:hover {
		background-image: url(/images/icons/menu-arrow.svg) !important;
		background-repeat: no-repeat !important;
		background-size: 14px 14px !important;
		background-position: 97% 50% !important;
	}
}

.megasubmenu.dropdown-menu {
  li {
    a {
      background-image: none !important;
    }
  }
}
