@media (max-width: 980px) {

  .uk-slideshow-items {
    width: 100%;
    height: 336px;
    li {
      width: 100%;
      height: 336px;
      a {
        img {
          width: 100%;
          height: 336px;
          object-fit: contain;
          object-position: center;
        }
      }
    }
  }

}

@media (max-width: 765px) {

	.topbar {
		text-align: left !important;
	}
	ul.topbar-menu {
		text-align: left;
	}

	.logo-link-swedmach {
		margin-top: 6px;
	}

  .sponsors {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }

	.header-search-desktop {
	  display: block;
	  padding-right: 10px;
	  padding-left: 10px;
	  position: relative;
	  top: -21px;
	  left: -10px;
	}

	ul.topbar-menu li a.lang {
	  padding: 10px 0 10px 1px;
	  position: relative;
	  top: 5px;
	}

	ul.topbar-menu li a {
	  display: inline-block;
	  color: #ffffff !important;
	  text-decoration: none;
	  padding: 10px 10px;
	  text-transform: uppercase;
	  font-size: 0.9rem;
	  font-weight: 300;
	  position: relative;
	  top: -9px;
	  left: -9px;
	}

	.logo {
		margin-bottom: 5px;
	}

	.logo-link-intra {
		position: relative;
		top: -8px;
	}

	.logo-link-swedmach {
		position: relative;
		top: -8px;
		img {
			top: 0 !important;
		}
	}

	.navbar {
	  display: block;
	  position: relative;
	  left: -1.5rem;
	  margin-bottom: 5px;
	  top: -7px;
	}

	.product-search {
	  display: block;
	  width: 100%;
	  padding: 8px;
	  font-size: 0.9rem;
	  margin-top: 14px;
		position: relative;
		left: -4px;
	}

	.header-search-desktop .search-result-box[data-v-40f8a9fe] {
	  position: absolute;
	  top: 61px;
	  left: 0px;
	  right: 0px;
	  padding: 5px;
	  background-color: #fff;
	  border: 1px solid #ccc;
	  z-index: 10000;
	  max-height: 60vh;
	  overflow: scroll;
	}

	.header-search-desktop .close-button[data-v-40f8a9fe] {
	  top: 9px;
	  right: 30px;
	}

	.header-search-desktop .search-result-box[data-v-40f8a9fe] {
	  position: absolute;
	  top: 42px;
	  left: 12px;
		right: 0px;
    padding: 5px;
	  background-color: #fff;
	  border: 1px solid #ccc;
  	z-index: 10000;
	  max-height: 60vh;
	  overflow: scroll;
	}

	.footer .pt-80:first-child {
		padding-top: 40px;
	}

	.footer .pb-80:first-child {
		padding-bottom: 0;
	}

	.footer .pt-80 {
		padding-top: 30px;
	}

	.footer .pb-80 {
		padding-bottom: 0;
	}

	.footer .pb-80:last-child {
		padding-bottom: 40px;
	}

	.product-list .row {
		margin-left: auto;
		margin-right: auto;
	}

	.product-tabs .nav {
	    display: block !important;
	    flex-wrap: wrap;
	    padding-left: 0;
	    margin-bottom: 0;
	    list-style: none;
	}

	.product-tabs .tab-regular .nav.nav-tabs .nav-link {
	    display: block !important;
	    width: 100% !important;
	}

  .uk-slideshow-items {
    width: 100%;
    height: 516px;
    li {
      width: 100%;
      height: 516px;
      a {
        img {
          width: 100%;
          height: 516px;
          object-fit: contain;
          object-position: center;
        }
      }
    }
  }

  .product-details {
    position: relative;
    top: 160px;

    &.video {
    	top: 60px;
    }
  }

	.carousel-control-prev,
	.carousel-control-next {
		display: none !important;
	}

}

@media (max-width: 440px) {

  ul.pagination {
    justify-content: space-evenly;
  }

  li.page-item {

        display: none;
    }

    .page-item:first-child,
    .page-item:last-child
    {

        display: block;
    }

    .page-item:last-child {
      margin-left: 20px;
    }

	#productPhotos .carousel-item {
	    max-height: 270px;
	}

	#productPhotos .carousel-item img {
	    width: 100%;
	    height: 270px;
	    -o-object-fit: contain;
	    object-fit: contain;
	    -o-object-position: center;
	    object-position: center;
	}

	.home-hero h1,
	.home-hero .h1 {
	    color: #ffffff !important;
	    text-align: center;
	    font-size: 1.6rem;
	    text-shadow: 3px 1px 3px rgb(0 0 0 / 57%);
	}

	.topbar-menu {
		position: relative;
		left: 8px;
		li {
			a {
				text-transform: none !important;
				display: inline-block;
				color: #ffffff !important;
				text-decoration: none;
				padding: 15px 3px 0 3px !important;
				font-size: 0.9rem;
				font-weight: 300;
			}
		}
	}

	ul.topbar-menu li a.lang {
	  padding: 10px 0 10px 1px;
	  position: relative;
	  top: -5px;
	  left: 10px;
	}

	.logo {
	    max-height: 30px !important;
			margin-top: 10px;
	    margin-bottom: 10px;
			margin-left: auto;
			margin-right: auto;
	}

	.header-search-mobile {
	    display: none;
			margin-top: 5px;
	}

	.header-search-desktop {
	    display: block;
	}

	.navbar {
		display: block;
		position: relative;
		left: 0;
		margin-bottom: 5px;
		.container-fluid {
			width: 100%;
			padding-right: 0;
			padding-left: 0;
			margin-right: auto;
			margin-left: auto;
		}
	}

	.product-list .carousel-item {
	  height: 270px;
	  max-height: 270px;
	  background: #ffffff !important;
	  border: 1px solid #ddd !important;
	}

	.product-list .carousel-item img {
	    width: 100%;
	    height: 270px;
	    -o-object-fit: contain;
	    object-fit: contain;
	}

  .sponsors {
    display: grid;
    grid-template-columns: 1fr;
  }

  .uk-slideshow-items {
    width: 100%;
    height: 416px;
    li {
      width: 100%;
      height: 416px;
      a {
        img {
          width: 100%;
          height: 416px;
          object-fit: contain;
          object-position: center;
        }
      }
    }
  }

  a[aria-label="Previous"] {
    span {
      &:after {
        content: "Föregående sida";
        margin: 0 0 0 5px;
      }
    }
  }

  a[aria-label="Next"] {
    span {
      &:before {
        content: "Nästa sida";
        margin: 0 5px 0 0;
      }
    }
  }


}
