.footer {
  background: $black;
  a {
    color: $white;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
  h1, h2, h3, h4 {
    color: $white;
  }
}

.smallprint {
	color: #666;
}

.footer-bottom {
	background: $light-grey;
	a {
		color: $black;
		text-decoration: none;
		&:hover {
			color: $black;
			text-decoration: underline;
		}
	}
}
